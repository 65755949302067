import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';
import { GetBrandSettingFromRecoil } from '../../recoil/brandsetting';
import { GetVideoIdFromRecoil } from '../../recoil/playlist';
import { activeQuestionState } from '../../recoil/poll';
import { GET_DISCUSSION_PROPERTIES } from '../queries/discussion';
import { GET_LIVE_DATA } from '../queries/live';
import { GET_MEDIA_DATA } from '../queries/media';
import { GET_PLAYLIST } from '../queries/playlist';
import { getCurrentItemFromPlaylist } from './player';

export const getContentParams = (
  mediaId,
  liveId,
  playlistId,
  webTVId,
  discussionId
) => {
  if (mediaId)
    return {
      query: GET_MEDIA_DATA,
      contentId: mediaId,
      contentType: 'media'
    };
  if (liveId)
    return {
      query: GET_LIVE_DATA,
      contentId: liveId,
      contentType: 'live'
    };
  if (playlistId)
    return {
      query: GET_PLAYLIST,
      contentId: playlistId,
      contentType: 'playlist'
    };
  if (webTVId)
    return {
      query: '',
      contentId: webTVId,
      contentType: 'webtv'
    };
  if (discussionId)
    return {
      query: GET_DISCUSSION_PROPERTIES,
      contentId: discussionId,
      contentType: 'discussion'
    };
  return {
    query: GET_MEDIA_DATA,
    contentId: null,
    contentType: null
  };
};

export const getContent = (media, live, playlist) => {
  if (!media && !live && !playlist) return {};
  const isMedia = false;
  const isLive = false;
  const isPlaylist = false;

  if (media)
    return {
      content: media,
      contentType: 'media',
      contentId: media.id,
      isMedia: true,
      isLive,
      isPlaylist
    };
  if (live)
    return {
      content: live,
      contentType: 'live',
      contentId: live.id,
      isMedia,
      isLive: true,
      isPlaylist
    };
  if (playlist) {
    return {
      content: playlist,
      contentType: 'playlist',
      contentId: playlist.id,
      isMedia,
      isLive,
      isPlaylist: true
    };
  }
};

export const getContentPlaying = (media, live, playlist) => {
  if (!media && !live && !playlist) return {};
  if (media)
    return {
      contentPlaying: media,
      contentTypePlaying: 'media',
      contentIdPlaying: media.id
    };
  else if (live)
    return {
      contentPlaying: live,
      contentTypePlaying: 'live',
      contentIdPlaying: live.id
    };
  else if (playlist) {
    const currentPlaylistContentId = GetVideoIdFromRecoil();
    const currentItemPlaying = getCurrentItemFromPlaylist(
      playlist,
      currentPlaylistContentId
    );
    return {
      contentPlaying: currentItemPlaying,
      contentTypePlaying:
        currentItemPlaying?.__typename === 'Media'
          ? 'media'
          : currentItemPlaying?.__typename === 'Live'
          ? 'live'
          : null,
      contentIdPlaying: currentItemPlaying?.id
    };
  }
};

export const getHeadInfos = (content, isLive) => {
  const { pageTitle, videoTitle, pageText } = GetBrandSettingFromRecoil();
  return {
    title: pageTitle || videoTitle || 'Streamfizz Player',
    description: pageText || '',
    image: isLive ? content?.waitingThumb : content?.selectedThumb
  };
};

const getFiles = (content, contentType, currentPlaylistContentId) => {
  const files = (
    contentType === 'playlist'
      ? getCurrentItemFromPlaylist(content, currentPlaylistContentId)
      : content
  )?.files;
  if (!files) return [];
  // filter to get only the active pdf files
  const activeFiles = files.filter(
    (file) => file.isActive && file.type === 'pdf'
  );
  return activeFiles;
};

const getTranscripts = (content, contentType, currentPlaylistContentId) => {
  const subtitles = (
    contentType === 'playlist'
      ? getCurrentItemFromPlaylist(content, currentPlaylistContentId)
      : content
  )?.subtitles;
  return subtitles || [];
};

const getChapters = (content, contentType, currentPlaylistContentId) => {
  const chapters = (
    contentType === 'playlist'
      ? getCurrentItemFromPlaylist(content, currentPlaylistContentId)
      : content
  )?.chapters;
  return chapters || [];
};

const getDiscussion = (content, contentType, currentPlaylistContentId) => {
  const discussion = (
    contentType === 'playlist'
      ? getCurrentItemFromPlaylist(content, currentPlaylistContentId)
      : content
  )?.discussion;
  return discussion || {};
};

export const handleSideBar = (
  content,
  contentType,
  currentPlaylistContentId
) => {
  const screens = useBreakpoint();
  const router = useRouter();

  // get files
  const files = getFiles(content, contentType, currentPlaylistContentId);
  const {
    shouldShowTranscript,
    shouldShowChapter,
    shouldShowFile,
    shouldShowDiscussion,
    shouldShowSideBar,
    shouldShowPoll
  } = GetBrandSettingFromRecoil();

  // get transcripts
  const transcripts = shouldShowTranscript
    ? getTranscripts(content, contentType, currentPlaylistContentId)
    : [];

  // get chapters
  const chapters = getChapters(content, contentType, currentPlaylistContentId);
  // const shouldShowFile = brandSetting?.shouldShowFile && files.length;
  const poll =
    (shouldShowPoll && content?.live?.poll) || !!content?.poll?.id || false;

  // get discussion
  const discussion = getDiscussion(
    content,
    contentType,
    currentPlaylistContentId
  );

  // based on above, let's define if the sidebar has any content to display
  const sidebarHasContent =
    !!(
      (shouldShowFile && files.length) ||
      (shouldShowTranscript && transcripts.length) ||
      shouldShowChapter ||
      (shouldShowFile && files.length) ||
      shouldShowDiscussion ||
      contentType === 'playlist' ||
      (shouldShowPoll && !screens.sm)
    ) &&
    !router?.query?.hidesidebar;

  return {
    files,
    transcripts,
    shouldShowChapter,
    shouldShowFile,
    chapters,
    discussion,
    shouldShowDiscussion,
    shouldShowTranscript,
    shouldShowPoll: poll,
    sidebarHasContent,
    shouldShowSideBar
  };
};
