import React from 'react';
// import "antd/dist/antd.dark.css";
const DarkTheme = () => (
  <style global jsx>{`
    body {
      color: rgba(255, 255, 255, 0.85);
      background: #141414;
    }
    p,
    //.ant-collapse-arrow,
    .card__content h3 {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-layout {
      background: #141414;
    }
    .ant-layout-header {
      color: rgba(255, 255, 255, 0.85);
      background: #141414 !important;
    }
    .ant-select {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent;
      border: 1px solid #434343;
    }
    .ant-select-dropdown {
      color: rgba(255, 255, 255, 0.85);
      background-color: #141414;
      box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
        0 9px 28px 8px rgb(0 0 0 / 5%);
    }
    .ant-select-single.ant-select-open .ant-select-selection-item {
      color: rgba(255, 255, 255, 0.3);
    }
    .ant-select-arrow {
      color: rgba(255, 255, 255, 0.3);
    }
    .ant-select-item {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: rgba(255, 255, 255, 0.85);
      background-color: #1f1f1f;
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: #1f1f1f;
    }
    .ant-layout-sider {
      background: #252525;
    }
    .ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: 1px solid #303030;
    }
    .ant-tabs,
    .ant-modal,
    .ant-empty-normal,
    h1,
    h2,
    h3 {
      color: rgba(255, 255, 255, 0.85) !important;
    }
    .ant-collapse-ghost {
      background-color: transparent !important;
    }
    .ant-input {
      color: rgba(255, 255, 255, 0.85);
      background-color: transparent;
      border: 1px solid #434343;
    }
    .ant-form-item-has-error .ant-input,
    .ant-form-item-has-error .ant-input-affix-wrapper,
    .ant-form-item-has-error .ant-input:hover,
    .ant-form-item-has-error .ant-input-affix-wrapper:hover {
      background-color: transparent !important;
    }
    .ant-timeline-item {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-modal-content {
      background-color: #141414;
      box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
        0 9px 28px 8px rgb(0 0 0 / 5%);
    }
    .ant-modal-header {
      color: rgba(255, 255, 255, 0.85);
      background: #141414;
      border-bottom: 1px solid #303030;
    }
    .ant-modal-title {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-modal-close {
      color: rgba(255, 255, 255, 0.45);
      background: transparent;
    }
    .ant-modal-close:focus,
    .ant-modal-close:hover {
      color: rgba(255, 255, 255, 0.75);
    }
    .ant-input-affix-wrapper-disabled {
      color: rgba(255, 255, 255, 0.3);
      background-color: rgba(255, 255, 255, 0.08);
    }
    .ant-input[disabled] {
      color: rgba(255, 255, 255, 0.3);
    }
    .ant-input-textarea-show-count::after {
      color: rgba(255, 255, 255, 0.3);
    }

    .ant-card {
      color: rgba(255, 255, 255, 0.85);
      background: #141414;
      border-radius: 2px;
      border: 1px solid #303030;
    }
    .ant-comment-content-author-name,
    .ant-comment-actions > li,
    .ant-comment-actions > li > span {
      color: rgba(255, 255, 255, 0.45) !important;
    }
    .ant-comment-content-author-time {
      color: rgba(255, 255, 255, 0.3);
    }
    .formDark {
      background: #252525;
    }
    .navbar {
      color: white;
      border-color: #303030 !important;
    }
    .ant-menu-overflow {
      background: #252525;
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-menu-horizontal > .ant-menu-item a,
    .ant-menu-title-content {
      color: rgba(255, 255, 255, 0.85);
    }
    .search-results {
      background: #414141;
    }
    .ant-input-affix-wrapper {
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 0;
      padding: 4px 11px;
      /* color: rgba(255, 255, 255, 0.85); */
      font-size: 14px;
      line-height: 1.5715;
      background-color: transparent;
      background-image: none;
      border: 1px solid #434343;
      border-radius: 2px;
      transition: all 0.3s;
      display: inline-flex;
    }

    .ant-btn .anticon {
      //color: rgba(255, 255, 255, 0.85);
    }
    .ant-layout-footer {
      background: #252525;
    }
    .algoliaBar{
      background: #252525;
    }
    .breadcrumb-container {
      background: #252525;
    }
    .contact-title,
    .contact-container {
      background-color: #252525 !important;
    }
    .contact-container p {
      color: #ffffff5c;
    }
    .ant-tabs,
    .ant-modal,
    .ant-empty-normal,
    h1,
    h2,
    h3 {
      color: rgba(255, 255, 255, 0.85) !important;
    }
    .algoliaBar{
      background: #252525 !important;
    }
    
    .ant-drawer-header {
      background: #252525 !important;
    }
    .ant-drawer-title {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-input-group-wrapper {
      background: #252525 !important;
    }
    .ant-drawer-body {
      background: #252525 !important;
    }
    .ant-menu-inline.ant-menu-root .ant-menu-item,
    .ant-menu-inline.ant-menu-root {
      background: #252525 !important;
    }
    .ant-menu-sub.ant-menu-inline {
      background: #252525 !important;
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-menu-item a {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-drawer-close{
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-drawer-close:focus, .ant-drawer-close:hover{
      color: rgba(255, 255, 255, 0.90);
    }
    .ant-breadcrumb {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(255, 255, 255, 0.85);
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      font-feature-settings: 'tnum';
      color: rgba(255, 255, 255, 0.45);
      font-size: 14px;
    }
    .ant-breadcrumb .anticon {
      font-size: 14px;
    }
    .ant-breadcrumb a {
      color: rgba(255, 255, 255, 0.45);
      transition: color 0.3s;
    }
    .ant-breadcrumb a:hover {
      color: #165996;
    }
    .ant-breadcrumb > span:last-child {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-breadcrumb > span:last-child a {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
      display: none;
    }
    .ant-breadcrumb-separator {
      margin: 0 8px;
      color: rgba(255, 255, 255, 0.45);
    }
    .ant-breadcrumb-link > .anticon + span,
    .ant-breadcrumb-link > .anticon + a {
      margin-left: 4px;
    }
    .ant-breadcrumb-overlay-link > .anticon {
      margin-left: 4px;
    }
    .ant-breadcrumb-rtl {
      direction: rtl;
    }
    .ant-breadcrumb-rtl::before {
      display: table;
      content: '';
    }
    .ant-breadcrumb-rtl::after {
      display: table;
      clear: both;
      content: '';
    }
    .ant-breadcrumb-rtl > span {
      float: right;
    }
    .ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + span,
    .ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + a {
      margin-right: 4px;
      margin-left: 0;
    }
    .ant-breadcrumb-rtl .ant-breadcrumb-overlay-link > .anticon {
      margin-right: 4px;
      margin-left: 0;
    }
  `}</style>
);

export default DarkTheme;
