import { atom, useRecoilValue } from 'recoil';

export const brandSettingState = atom({
  key: 'brandSettingState',
  default: {
    accentColor: '#fff',
    pageTheme: '',
    pageLogo: '',
    pageTitle: '',
    pageText: '',
    shouldShowSummary: false,
    shouldShowShareButton: false,
    shouldShowSideBar: false,
    shouldShowPoll: false,
    shouldShowDiscussion: false,
    shouldShowTranscript: false,
    shouldShowChapter: false,
    shouldShowFile: false,
    watermarkEnabled: false,
    watermarkPosition: '',
    watermarkLink: '',
    watermarkImage: '',
    privacyType: '',
  }
});

export const GetBrandSettingFromRecoil = () => {
    const recoilBrandSetting = useRecoilValue(brandSettingState);
    const copyRecoilBrandSetting = {...recoilBrandSetting};
    if (!copyRecoilBrandSetting.accentColor) {
        copyRecoilBrandSetting.accentColor = '#fff';
    }
    return copyRecoilBrandSetting;
}