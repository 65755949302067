import { parseCookies, destroyCookie, setCookie } from 'nookies';

export const setFormDataCookie = (formData) => {
  try {
    setCookie(null, 'formData', JSON.stringify(formData), {
      sameSite: 'None',
      secure: true,
      maxAge: 3 * 60 * 60
    });
  } catch (error) {}
};

export const getFormDataCookie = () => {
  const formDataCookie = parseCookies()['formData'];
  return formDataCookie ? JSON.parse(formDataCookie) : {privacyType: null, data: []};
};

export const destroyFormDataCookie = () => {
  destroyCookie({}, 'formData');
};

export const formatDiscussionFields = ({
  discussionFields,
  auth0Profile = {},
  formData = []
}) => {
  const data = discussionFields
    .filter((field) => field.isActive)
    .map((field) => {
      const auth0Field = auth0Profile && auth0Profile[field.formLabel.label];
      const formDataField =
        formData.length && formData?.find((e) => e.id === field.id);
      return {
        id: field.id,
        value: auth0Field || formDataField?.value || null,
        label: field.formLabel.label,
        isMandatory: field.isMandatory,
        isDisplayKey: field.isDisplayKey,
        isDisabled: !!auth0Field
      };
    });
  return data;
};

export const formatFieldsData = (data) =>
  data
    .filter((item) => item.value)
    .map((item) => ({
      messageFieldId: item.id,
      value: item.value
    }));
