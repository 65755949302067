import { gql } from '@apollo/client';

export const GET_LIVE_DATA = gql`
  query getLiveData($liveId: ID!) {
    live(id: $liveId) {
      id
      status
      title
      description
      liveUrl
      waitingThumb
      startDate
      disableAnalytic
      shouldShowCountdown
      confidentiality {
        stringValue
      }
      poll {
        id
        pollQuestions {
          id
        }
      }
      chapters {
        id
      }
      hasDVR
      hasReplay
      replay {
        id
        isEncodingDone
      }
      publisher {
        id
      }
      discussion {
        id
        preset
      }
      pageSetting {
        id
        shouldShowSummary
        shouldShowDiscussion
        shouldShowShareButton
        shouldShowTranscript
        shouldShowChapter
        shouldShowFile
        pageTitle
        videoTitle
        pageText
        pageTheme
        pageLogo
        accentColor
        watermarkEnabled
        watermarkPosition
        watermarkLink
        watermarkImage
        shouldShowSideBar
        restrictedDomains{id,domain}
      }
      embedSetting {
        id
        shouldShowSummary
        shouldShowDiscussion
        shouldShowShareButton
        shouldShowTranscript
        shouldShowChapter
        shouldShowFile
        pageTitle
        videoTitle
        pageText
        pageTheme
        pageLogo
        accentColor
        watermarkEnabled
        watermarkPosition
        watermarkLink
        watermarkImage
        shouldShowSideBar
        restrictedDomains{id,domain}
        privacyType
      }
    }
  }
`;
