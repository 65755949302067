import { gql } from '@apollo/client';

export const GET_DISCUSSION_PROPERTIES = gql`
  query getDiscussionProperties($id: ID!, $webTvId: ID, $playlistId: ID, $isAnimateur: Boolean) {
    discussion(discussionId: $id, webTvId: $webTvId, playlistId: $playlistId, isAnimateur: $isAnimateur) {
      id
      shouldShowMessages
      shouldAllowLike
      shouldAllowAnswer
      isEmbed
      preset
      placeholder
      isCensorship
      # moderator {
      #   id
      #   name
      # }
      fields {
        id
        isMandatory
        isDisplayKey
        isActive
        formLabel {
          id
          type
          label
        }
      }
      live {
        id
        title
        confidentiality {
          stringValue
        }
        pageSetting {
          accentColor
          pageTitle
          pageLogo
          pageTheme
        }
      }
      media {
        id
        title
        confidentiality {
          stringValue
        }
        pageSetting {
          accentColor
          pageTitle
          pageLogo
          pageTheme
        }
      }
    }
  }
`;

export const GET_DISCUSSION_PRESET = gql`
  query getDiscussionPreset($id: ID!, $isAnimateur: Boolean) {
    discussion(discussionId: $id, isAnimateur: $isAnimateur) {
      id
      preset
    }
  }
`;

export const GET_DISCUSSION_MESSAGES_PUBLIC = gql`
  query getMessageFeed($discussionId: ID!, $offset: Int, $limit: Int, $cursor: String, $webTvId: ID, $playlistId: ID) {
    messageFeed(discussionId: $discussionId, offset: $offset, limit: $limit, cursor: $cursor, webTvId: $webTvId, playlistId: $playlistId) {
      messageFeedId
      messages {
        id
        createdAt
        updatedAt
        value
        likes
        fromModerator
        moderatorContent {
          id
          pseudo
        }
        isVisible
        isArchived
        fieldValues {
          id
          value
          field {
            id
            isDisplayKey
            formLabel {
              label
            }
          }
        }
        answers {
          id
          value
          likes
          createdAt
          updatedAt
          fromModerator
          isVisible
          moderatorContent {
          id
          pseudo
        }
          fieldValues {
            id
            value
            field {
              id
              isDisplayKey
              formLabel {
                label
              }
            }
          }
        }
      }
      cursor
    }
  }
`;

// Question direct 
export const GET_DISCUSSION_MESSAGES_ANIMATEUR = gql`
  query getMessageAnimateurFeed($id: ID!) {
    messageAnimateurFeed(id: $id) {
      messageAnimatorFeedId
      messages {
        id
        createdAt
        updatedAt
        value
        likes
        fromModerator
        isVisible
        isArchived
        isDeleted
        presenterOnly
        isShownToPresenter
        moderatorContent {
          id
          pseudo
        }
        fieldValues {
          id
          value
          field {
            id
            isDisplayKey
            formLabel {
              label
            }
          }
        }
        answers {
          id
          value
          likes
          createdAt
          updatedAt
          isDeleted
          isArchived
          fromModerator
          isVisible
          fieldValues {
            id
            value
            field {
              id
              isDisplayKey
              formLabel {
                label
              }
            }
          }
        }
      }
      # moderatorMessages {
      #   id
      #   createdAt
      #   updatedAt
      #   value
      #   likes
      #   fromModerator
      #   isVisible
      #   isArchived
      #   fieldValues {
      #     id
      #     value
      #     field {
      #       id
      #       isDisplayKey
      #       formLabel {
      #         label
      #       }
      #     }
      #   }
      #   answers {
      #     id
      #     value
      #     likes
      #     createdAt
      #     updatedAt
      #     fromModerator
      #     isVisible
      #     fieldValues {
      #       id
      #       value
      #       field {
      #         id
      #         isDisplayKey
      #         formLabel {
      #           label
      #         }
      #       }
      #     }
      #   }
      # }
    }
  }
`;


// Message de la moderation
export const GET_MESSAGE_ANIMATOR_FEED = gql`
  query MessageToAnimator($messageToAnimatorId: ID!) {
    messageToAnimator(id: $messageToAnimatorId) {
      messageToAnimatorId
      messages {
        id
        createdAt
        updatedAt
        value
        likes
        fromModerator
        isVisible
        isArchived
        presenterOnly
        moderatorContent {
          id
          pseudo
        }
        fieldValues {
          id
          value
          field {
            id
            isDisplayKey
            formLabel {
              label
            }
          }
        }
        answers {
          id
          value
          likes
          createdAt
          updatedAt
          fromModerator
          isVisible
          fieldValues {
            id
            value
            field {
              id
              isDisplayKey
              formLabel {
                label
              }
            }
          }
        }
      }
    }
  }
`;
